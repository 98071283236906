<template>
  <a-modal
    title="上传文件"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel">
    <a-spin :spinning="confirmLoading">
      <a-upload
        name="file"
        :multiple="true"
        accept="image/*,.doc,.docx,.json,.xlsx,.xls"
        :action="url"
        :headers="headers"
        @change="handleChange"
        :remove="handleRemove"
        :file-list="fileList"
      >
        <a-button> <a-icon type="upload" />选择文件</a-button>
      </a-upload>
    </a-spin>
  </a-modal>
</template>

<script>
import { addFile } from '@/api/system/source'
import { getToken } from "@/utils/auth";

export default {
  name: 'SourceFileUploadModal',
  data () {
    return {
      url: process.env.VUE_APP_API_BASE_URL + '/file/upload',
      headers: { 'Authorization': 'Bearer ' + getToken() },
      visible: false,
      confirmLoading: false,
      fileList: [],
      fileItems: [],
      loading: false,
      id: null,
      parentId: 0
    }
  },
  methods: {

    add (parentId) {
      this.parentId = parentId
      this.visible = true
    },

    handleSubmit () {
      this.confirmLoading = true;
      const data = {parentId: this.parentId, fileItems: this.fileItems}
      addFile(data).then(res =>{
        if (res.code === 200) {
          this.$notification.success({message: '上传成功'})
          this.handleCancel()
          this.$emit('ok', data)
        }
      }).finally(()=> {
        this.confirmLoading = false;
      })
    },

    handleRemove(file) {
      const res = file.response
      if (res && res.code == 200) {
        this.fileItems = this.fileItems.filter(item => {
          return item.sid != res.data.sid
        })
      }
    },

    handleChange(info) {
      this.confirmLoading = true
      if (info.file.status === 'done') {
        const res = info.file.response
        if (res && res.code == 200) {
          this.fileItems.push({
            name: res.data.name,
            sid: res.data.sid,
            url: res.data.src,
            suffix: res.data.suffix
          })
        }
        this.confirmLoading = false
      }
      this.fileList = info.fileList;
    },

    handleCancel () {
      this.fileList = []
      this.fileItems = []
      this.visible = false
      this.id = undefined
    },
  }
}
</script>
